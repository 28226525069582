<template>
  <div class="discount">
    <h1>Регистрация карты</h1>
    <br>
    <div v-if="added">
      <b>Карта активирована</b><br><br>
      <button @click="back" class="btn btn-primary">Назад</button>
    </div>
    <form v-else @submit="checkForm" class="form">
      <div class="errors" v-if="errors.length">
        <b>Пожалуйста исправьте указанные ошибки:</b>
        <ul>
          <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
        </ul>
      </div>
      <div class="inputs">
        <div class="mb-3">
          <input required type="text" v-model="lastName"  class="form-control" placeholder="Фамилия">
        </div>
        <div class="mb-3">
          <input required type="text" v-model="firstName" class="form-control" placeholder="Имя">
        </div>
        <div class="mb-3">
          <input required type="text" v-model="middleName" class="form-control" placeholder="Отчество">
        </div>
        <div class="mb-3">
          <input required type="text" v-model="value" v-maska="{ mask: '!GZ0000######' }" class="form-control" placeholder="Номер карты">
        </div>
        <div class="mb-3">
          <input required type="text" v-model="phone" v-maska="{ mask: '!+7##########' }" class="form-control" placeholder="Телефон">
        </div>
      </div>
      <div>
        <button type="submit" class="btn btn-primary mb-3">Активировать</button>
      </div>
    </form>
  </div>
</template>

<script>
import * as api from '../services/api'
export default {
  name: 'DiscountForm',
  data() {
    return {
      errors: [],
      lastName: '',
      firstName: '',
      middleName: '',
      value: 'GZ0000',
      phone: '+7',
      added: false,
    }
  },
  methods: {
    back: function () {
      this.lastName = ''
      this.firstName = ''
      this.middleName = ''
      this.value = 'GZ0000'
      this.phone = '+7'
      this.added = false
    },
    checkForm: async function (e) {
      e.preventDefault()
      this.errors = []
      api.createCard(process.env.VUE_APP_API_URL, {
        last_name: this.lastName,
        first_name: this.firstName,
        middle_name: this.middleName,
        value: this.value,
        phone: this.phone
      }).then(async (data) => {
        const res = await data.json()
        if(res.status === 'error') {
          if (typeof res.message === 'string') {
            if (res.message.includes('Duplicate')) {
              this.errors.push('Карта с такми номером уже зарегистрирована')
            } else {
              this.errors.push(res.message)
            }
          } else {
            for (const [, value] of Object.entries(res.message)) {
              this.errors.push(value[0]);
            }
          }
        } else {
          this.added = true
        }
      })

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.inputs {
  margin-left: auto;
  margin-right: auto;

  width: 300px;
  text-align: right;
}

.errors {
  margin-left: auto;
  margin-right: auto;

  width: 300px;
  text-align: left;

  font-size: small;
  color: #ce8483;
}
</style>
